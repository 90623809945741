import React from "react";

function Aboutus() {
  return (
    <div className=" bg-[#f3f3f3] md:pt-16 pb-16 pt-8 w-full px-8 md:px-32 mx-auto">
      <h1 className="text-4xl py-4 text-green-600 text-left font-bold">
        Overview
      </h1>
      <div className="text-black mx-auto  items-center  ">
      International Institute of Information Technology, Bhubaneswar was established by the Government of Odisha in 2006. It is designed to be a premier institute of Information Technology and related areas. The Institute has been converted into a unitary university by the Government of Odisha on 20th Jan 2014. The mission of IIIT is to be a knowledge seeking Institution of higher learning that will educate students in technology and other disciplines of scholarship. The Institute will work closely with the industry and other users of the technology to develop and deliver technological solutions to enhance their competitive position. The Institute is committed to the entire value chain of knowledge creation, diffusion and preservation to meet the challenges of the century. The Institute will borrow best practices in education delivery systems, research and consulting practices. Leveraging technology to bring about next generation of practices will be a key to this strategy. The Institute is dedicated to creating a community of students, faculty and scholars with passion for learning, creativity, innovation in all aspects of academic pursuit.
        <div className="font-semibold mt-4">
          ASPCC is a biennial research-oriented conference jointly conducted by
          the Department of Electronics & Telecommunication Engineering and
          Department of Electrical Engineering, IIIT Bhubaneswar in collabortion
          with IEEE Student branch,IIIT Bhubaneswar .The goal of the conference
          is to bring together leading scientists, researchers, and engineers,
          developers and academicians working in the cutting-edge technology to
          discuss novel theories, technologies and applications in the fields of
          signal processing, Power, communications and computing. The conference
          will feature prominent plenary speakers as well as technical sessions.
          The conference shall be organised in technical collabortion with IEEE
          Kolkata section, IEEE Bhubaneswar Subsection and IEEE Communications
          and Computer Joint Societies chapter, Chandipur.
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
